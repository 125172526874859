"use client";
import { useIsMobile } from "@/hooks/use-mobile";
import { Menu } from "lucide-react";
import { Button } from "@/components/ui/button";
import Link from "next/link";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerFooter,
  DrawerTrigger,
} from "@/components/ui/drawer";

type NavLink = {
  href: string;
  label: string;
};

export default function MobileNavWrapper({ links, desktopNav }: { links: NavLink[]; desktopNav: React.ReactNode }) {
  const isMobile = useIsMobile();

  if (!isMobile) {
    return <>{desktopNav}</>;
  }

  return (
    <div className="flex items-center gap-2">
      <Drawer>
        <DrawerTrigger asChild>
          <Button variant="ghost" size="icon">
            <Menu className="h-6 w-6" />
          </Button>
        </DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle className="text-center">Navigation</DrawerTitle>
            <div className="flex flex-col gap-4 p-4">
              {links.map((link) => (
                <DrawerClose key={link.href} asChild>
                  <Link href={link.href} className="block py-2 px-4 hover:bg-accent rounded-md transition-colors">
                    {link.label}
                  </Link>
                </DrawerClose>
              ))}
            </div>
          </DrawerHeader>
          <DrawerFooter>
            <DrawerClose>
              <Button>Close</Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
